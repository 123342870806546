// client/src/components/Login.js
import React, { useState } from "react";
import axiosInstance from "../axiosConfig";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/auth/login", { username, password });
      localStorage.setItem("token", response.data.token);
      window.location.href = "/upload";
    } catch (error) {
      alert('Invalid username or password')
      console.log("Login error:", error);
    }
  };

  return (
    <div className="form-container">
      <form className="form" onSubmit={handleLogin}>
        <h2>Login</h2>
        <input 
          type="text" 
          className="form-input" 
          placeholder="Username" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
        />
        <input 
          type="password" 
          className="form-input" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <button type="submit" className="form-button">Login</button>
        <p className="form-link">
          Don't have an account? <a href="/register">Register</a>
        </p>
      </form>
    </div>
  );
};

export default Login;
