import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import "./FileList.css";  // Import the CSS file for styling

const FileList = () => {
  const [files, setFiles] = useState([]);
  const [copiedLink, setCopiedLink] = useState(""); // Track copied link status

  // Fetch the list of files from the backend when the component mounts
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get("/files/stats", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Ensure user is authenticated
          },
        });
        setFiles(response.data);
      } catch (error) {
        alert("Error fetching files:", error);
      }
    };

    fetchFiles();
  }, []);

  // Handle file view increment (on click, for example)
  const handleViewFile = async (fileId) => {
    try {
      await axiosInstance.get(`/files/view/${fileId}`);
      // Update the file's view count in the UI immediately after it's viewed
      setFiles(files.map(file => 
        file._id === fileId ? { ...file, views: file.views + 1 } : file
      ));
    } catch (error) {
      alert("Error viewing file:", error);
    }
  };

  // Function to handle copying the file URL to clipboard with fallback to execCommand()
  const handleCopyLink = (fileUrl) => {
    // if (navigator.clipboard) {
    //   // Use Clipboard API if it's available (HTTPS requirement)
    //   navigator.clipboard.writeText(fileUrl)
    //     .then(() => {
    //       setCopiedLink(fileUrl); // Update copied link state
    //       setTimeout(() => setCopiedLink(""), 2000); // Reset copied link after 2 seconds
    //     })
    //     .catch((error) => {
    //       console.log("Error copying text using Clipboard API: ", error);
    //       fallbackCopyToClipboard(fileUrl); // Fallback to execCommand if Clipboard API fails
    //     });
    // } else {
      // Fallback to execCommand if Clipboard API is unavailable (HTTP domains, or older browsers)
      fallbackCopyToClipboard(fileUrl);
    // }
  };

  // Fallback method using document.execCommand() for older browsers or HTTP domains
  const fallbackCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopiedLink(text); // Update copied link state
    setTimeout(() => setCopiedLink(""), 2000); // Reset copied link after 2 seconds
  };

  return (
    <div className="file-list-container">
      <h2 className="file-list-title">Uploaded Files</h2>
      <ul className="file-list">
        {files.map((file) => (
          <li key={file._id} className="file-item">
            <div className="file-header">
              <h3>{file.filename}</h3>
              <p className="file-tags">Tags: {file.tags.join(", ")}</p>
            </div>
            <div className="file-stats">
              <p>Size: {(file.size / 1024).toFixed(2)} KB</p>
              <p>Views: {file.views}</p>
              <p>Created On: {new Date(file.creationDate).toLocaleDateString()}</p>
            </div>
            <div className="file-actions">
              {/* When user clicks the link, increment the view count and show the file */}
              <a
                href={`http://164.90.175.91:5000/api/files/uploads/${file.filename}`}
                target="_blank"
                rel="noopener noreferrer"
                className="file-link"
                onClick={() => handleViewFile(file._id)}
              >
                Download/Preview
              </a>
              {/* Copy Link button */}
              <button
                className="copy-link-btn"
                onClick={() => handleCopyLink(`http://164.90.175.91:5000/api/files/uploads/${file.filename}`)}
              >
                Copy Link
              </button>
              {/* Show a notification when the link is copied */}
              {copiedLink === `http://164.90.175.91:5000/api/files/uploads/${file.filename}` && (
                <span className="copy-link-notification">Link copied!</span>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileList;
