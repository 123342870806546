// client/src/components/Register.js
import React, { useState } from "react";
import axiosInstance from "../axiosConfig";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/auth/register", { username, password });
      window.location.href = "/";
    } catch (error) {
      console.log(error)
      const errorMessage = "The username is already taken. Please choose a different one.";
      alert(errorMessage);
    }
  };

  return (
    <div className="form-container">
      <form className="form" onSubmit={handleRegister}>
        <h2 className="form-title">Register</h2>
        <input 
          type="text" 
          className="form-input" 
          placeholder="Username" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
        />
        <input 
          type="password" 
          className="form-input" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <button type="submit" className="form-button">Register</button>
        <p className="form-link">
          Already have an account? <a href="/">Login</a>
        </p>
      </form>
    </div>
  );
};

export default Register;
