import React, { useState } from "react";
import axiosInstance from "../axiosConfig";

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [tags, setTags] = useState("");

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("tags", tags);

    try {
      await axiosInstance.post("/files/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      alert("File uploaded successfully!");
      // Clear the form after successful upload
      setFile(null);
      setTags("");
    } catch (error) {
      alert("File upload error:", error);
    }
  };

  return (
    <div className="form-container">
      <form className="form" onSubmit={handleFileUpload}>
        <h2>Upload File</h2>

        {/* File Input */}
        <input 
          type="file" 
          className="form-input" 
          onChange={(e) => setFile(e.target.files[0])} 
          required 
        />
        
        {/* Tags Input */}
        <input 
          type="text" 
          className="form-input" 
          placeholder="Tags (comma separated)" 
          value={tags} 
          onChange={(e) => setTags(e.target.value)} 
          required 
        />
        
        {/* Upload Button */}
        <button type="submit" className="form-button">Upload</button>
        
        {/* View All Files Button */}
        <a href="/files" className="form-link">View All Files</a>
      </form>
    </div>
  );
};

export default FileUpload;
